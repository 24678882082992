import axios from '../../plugins/axios';
import * as Sentry from '@sentry/vue';
import router from '../../router';
import { storeKey } from '../index';

const defaultUser = {
  id: null,
  super_admin: false,
  default_organization_id: null,
  admin: false,
  name: null,
  title: null,
  email: null,
  phone: null,
  license: null,
  created_at: null,
  profile_photo_id: null,
  profile_photo_key: Math.random(),
  profile_photo_url: null,
  dashboard: null,
};

const state = () => ({
  user: {
    id: null,
    super_admin: false,
    default_organization_id: null,
    admin: false,
    name: null,
    title: null,
    email: null,
    phone: null,
    license: null,
    created_at: null,
    profile_photo_id: null,
    profile_photo_key: Math.random(),
    profile_photo_url: null,
    dashboard: 'classic',
  },
});

const getters = {
  user: (state) => {
    return state.user;
  },
};

const actions = {
  getUser({ commit }) {
    return axios.get('/api/v1/user').then((response) => {
      commit('setUser', response.data.payload);
    });
  },
  async logout({ commit }) {
    // end the session on the server
    await axios.post('/logout');

    // reset the user store
    commit('setUser', defaultUser);
    commit('users/updateUsers', [], { root: true });

    if (localStorage) {
      console.log('removing storeKey', storeKey);
      localStorage.removeItem(storeKey);
    }

    // reset settings
    commit('settings/resetSettings', {}, { root: true });

    return router.push({ name: 'login' });
  },
  async getUserCommunicationPreferences(context, { buildingId }) {
    let url = '/api/v1/core/user/communication_preferences';
    if (buildingId) {
      url += '?buildingId=' + buildingId;
    }
    const response = await axios.get(url);
    return response.data.payload;
  },
  updateUserCommunicationPreference(ctx, { id, active, buildingId, allBuildings }) {
    axios.post('/api/v1/core/user/communication_preference', {
      id,
      active,
      buildingId,
      allBuildings,
    });
  },
};

const mutations = {
  setUser(state, user) {
    state.user = { ...state.user, ...user };

    axios.defaults.headers.common['X-Tenant'] = user.default_organization_id;

    // set user context for bugsnag
    Sentry.setUser({ id: state.user.id, email: state.user.email, name: state.user.name });
  },
  setUserCommunicationPreferences(state, preferences) {
    state.communication_preferences = preferences;
  },
  updateName(state, name) {
    state.user.name = name;
  },
  updateTitle(state, title) {
    state.user.title = title;
  },
  updateEmail(state, email) {
    state.user.email = email;
  },
  updatePhone(state, phone) {
    state.user.phone = phone;
  },
  updateProfilePhotoId(state, id) {
    state.user.profile_photo_id = id;
  },
  updateProfilePhotoUrl(state, src) {
    state.user.profile_photo_url = src;
  },
  updateProfilePhotoKey(state, key) {
    state.user.profile_photo_key = key;
  },
  updateUserDashboard(state, dashboard) {
    console.log('updateUserDashboard', dashboard);
    state.user.dashboard = dashboard;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

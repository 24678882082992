export default {
  Tasks: {
    Status: {
      OPEN: 'open',
      IN_PROGRESS: 'in_progress',
      CLOSED: 'closed',
      SCHEDULED: 'scheduled',
      CANCELLED: 'cancelled',
      COMPLETED: 'completed',
    },
    Source: {
      INSPECTION: 'inspection',
      SPECIAL_PROJECT: 'special_project',
      TASK: 'task',
      CLEANCODE: 'cleancode',
      PRISM: 'prism',
    },
    Transition: {
      BEGIN: 'begin',
      CANCEL: 'cancel',
      CLOSE: 'close',
      REOPEN: 'reopen',
    },
    Type: {
      DEFICIENCY: 'deficiency',
      REQUEST: 'request',
      REMINDER: 'reminder',
      SERVICE: 'service',
      WORK_ORDER: 'work_order',
      OTHER: 'other',
    },
    Visibility: {
      PUBLIC: 'public',
      INTERNAL: 'internal',
    },
  },
  SpecialProjects: {
    Type: {
      ACID_WASHING: 'acid washing',
      APPLIANCE_CLEANING: 'appliance cleaning',
      CARPET_CLEANING: 'carpet cleaning',
      DETAIL_CLEANING: 'detail cleaning',
      EVENT_CLEANING: 'event cleaning',
      DISINFECTION_SERVICE: 'disinfection service',
      EXTRA_LABOR_REQUEST: 'extra labor request',
      GLASS_CLEANING: 'glass cleaning',
      FIELD_BONUS: 'field bonus',
      HARD_SURFACE_FLOOR_WORK: 'hard surface floor work',
      POWER_WASHING: 'power washing',
      PRODUCT_BILL_BACK: 'product bill-back',
      SUBCONTRACT: 'subcontract',
      UPHOLSTERY_CLEANING: 'upholstery cleaning',
      CLEANCODE: 'cleancode',
      OTHER: 'other',
    },
  },
};

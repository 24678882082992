import { useWinteamApi } from '../../composables/useWinteamApi.js';

const state = () => ({
  customers: [],
});

const getters = {};

const mutations = {
  updateCustomers(state, customers) {
    state.customers = customers;
  },
};

const actions = {
  getCustomers(context) {
    const winteamApi = useWinteamApi();
    return winteamApi.getWinteamCustomers().then((customers) => {
      const updatedDCustomers = customers.map((customer) => {
        customer.full_title = `${customer.customer_number}: ${customer.customer_name} - ${winteamApi.customerType(
          customer.customer_type_id
        )}`;
        return customer;
      });
      context.commit('updateCustomers', updatedDCustomers);
      return updatedDCustomers;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import axios from '../plugins/axios.js';
export function useWinteamApi() {
  const customerTypes = [
    { title: 'Management Company', value: 2 },
    { title: 'Tenant', value: 5 },
  ];

  const getWinteamCustomers = async (buildingId) => {
    console.log('getWinteamCustomers', buildingId);
    let url = '/api/v1/integrations/winteam/customers';
    if (buildingId) {
      url = `${url}?building_id=${buildingId}`;
    }
    const response = await axios.get(url);
    return response.data.payload;
  };

  const customerType = (typeId) => {
    const type = customerTypes.find((type) => type.value === typeId);
    return type ? type.title : '';
  };

  return { getWinteamCustomers, customerTypes, customerType };
}
